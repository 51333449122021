.bannerbody{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #23E3c9; */
}

.slider{
    width: 100%;
    height: 600px;
    overflow: hidden;
}

.slides{
    width: 500%;
    height: 600px;
    display: flex;
}

.slides input{
    display: none;
}

.slide{
    width: 20%;
    transition: 2s;
}

.slide img{
    width: 100%;
    height: 600px;
}

/* css for manual slide navigation */

.navigation-manual{
    position: absolute;
    width: 100%;
    margin-top: -40px;
    display: flex;
    justify-content: center;
}

.manual-btn{
    border: 2px solid #40D3DC;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    transition: 1s;
}

.manual-btn:not(:last-child){
    margin-right: 40px;
}

.manual-btn:hover{
    background: #40D3DC;
}

#radio1:checked ~ .first{
    margin-left: 0;
}

#radio2:checked ~ .first{
    margin-left: -20%;
}

#radio3:checked ~ .first{
    margin-left: -40%;
}

#radio4:checked ~ .first{
    margin-left: -60%;
}

/* css for automatic navigation */
.navigation-auto{
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 560px;
}

.navigation-auto div{
    border: 2px solid #40D3DC;
    padding: 5px;
    border-radius: 10px;
    transition: 1s;
}

.navigation-auto div:not(:last-child){
    margin-right: 40px;
}

#radio1:checked ~ .navigation-auto .auto-btn1{
    background: #40D3DC;
}

#radio2:checked ~ .navigation-auto .auto-btn2{
    background: #40D3DC;
}

#radio3:checked ~ .navigation-auto .auto-btn3{
    background: #40D3DC;
}

#radio4:checked ~ .navigation-auto .auto-btn4{
    background: #40D3DC;
}

@media only screen and (min-width: 320px) and (max-width: 830px){
    .slider{
        width: 400px;
        height: 250px;
        border-radius: 10px;
        overflow: hidden;
    }

    .slide img{
        width: 100%;
        height: 50%;
        object-fit: cover;
    }
    .bannerbody{
        margin-top: -75px;
        margin-bottom: -75px;
    }

    .navigation-manual{
        position: absolute;
        width: 400px;
        display: flex;
        justify-content: center;
        margin-top: -370px;
    }

    .navigation-auto{
        position: absolute;
        display: flex;
        width: 400px;
        justify-content: center;
        margin-top: 230px;
    }

    .manual-btn:not(:last-child){
        margin-right: 20px;
    }

    .navigation-auto div:not(:last-child){
        margin-right: 20px;
    }
}