.body_div{
    margin: 2rem 2rem;
    width: 100%;
}

.alert{
    margin: 0;
    padding: 0;
    height: 60px;
    background: #EC6A1F;
    color: #011E3A;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.notifications h2{
    margin-top: 10px;
}

.news{
    animation : slide 15s reverse infinite;
}

.why-img{
  /* background-image:url('../../assets/img/question.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  height: 520px;
  padding: 10px 10px 10px 10px;
  background-color: rgba(0,0,0,.125);
}

.whyimg{
  height: 500;
  width: 700px;
}
@keyframes slide {
    0% {
      transform: translatex(0%)
    }
  
    100% {
      transform: translatex(100%)
    }
  } 

  .school-routes{
    text-align: center;
  }

  p{
    padding-top: 10px;
    font-weight: 400;
    text-align: left;
  }
  .h2-title{
    color: #EC6A1F;
    text-align: left;
  }
  h4{
    text-align: left;
  }
  .card {
    background: #fff;
    border-radius: 2px;
    display: inline-block;
    height: 300px;
    margin: 1rem;
    position: relative;
    width: 400px;
    
  }

  .card1 {
    background: #fff;
    border-radius: 2px;
    display: inline-block;
    height: 370px;
    margin: 2rem;
    position: relative;
    width: 250px;
  }

  .card-1 {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }
  
  .card-1:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  .card-desc{
    margin: 20px 20px;
    text-align: left;
  }

  @media only screen and (min-width: 320px) and (max-width: 830px){
    .card{
      width: 300px;
    }
    .card1{
      width: 230px;
    }
    .card img{
      width: 300px;
    }
    .card1 img{
      width: 230px;
    }
    .welcome img{
      width: 350px;
      height: 250px;
    }
    .whyimg{
      height: 220px;
      width: 350px;
    }
}


.marquee {
  width: 100%;
  line-height: 50px;
  background-color: #EC6A1F;
  color: #011E3A;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 24px;
}
.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 20s linear infinite;
  padding-top: 5px;
}
@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}