
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Titillium Web', sans-serif;
}

:root{
    --mainColor:#011E3A;
    --mainColorLight: #5767aa;
    --secondaryColor: #DB2B39;
    --menuColor: #EC6A1F;
    --textColor: #eee;
}

header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 2rem;
    /* background-color: var(--mainColor); */
    background-color: #fff;
    color: var(--textColor);
    position: fixed;
    width: 100%;
    z-index: 1000;
    margin-top: 40px;
}

nav a{
    margin: 0 25px;
    /* color: var(--textColor); */
    color: var(--mainColor);
    text-decoration: none;
}

nav a:hover{
    color: var(--menuColor);
}

header .nav-btn{
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--menuColor);
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}


header img{
    border-radius: 20px;
    height: 100px;
    width: 120px;
    z-index: 1000;
}

.sticky{
    transition: 1s;
    height: 45px;
}

@media only screen and (max-width:1100px){
    header .nav-btn{
        visibility: visible;
        opacity: 1;
    }
    header{
        position: relative;
        margin-top: 0px;
    }
    header nav{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: #fff;
        transition: 1s;
        transform: translateY(-100vh);
        z-index: 3;
    }

    header .responsive_nav{
        transform: none;
    }

    nav .nav-close-btn{
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    nav a{
        font-size: 1.5rem;
    }

    header img{
        margin-top: 10px;
        border-radius: 20px;
        height: 70px;
        width: 80px;
        z-index: 1;
    }

    .sticky{
        position: fixed;
        transition: 1s;
        height: 55px;
    }
}



#banner{
    min-height: 400px;
}

#infobar{
    min-height: 55px;
}
  
/* Clear floats after the columns */
.row:after {
content: "";
display: table;
clear: both;
}

.txt{
width: 250px;
height: 40px;
margin: 5px 2px 2px 2px;
border-radius: 5px;
outline-color: #5767aa;
text-align: left;
text-indent: 10px;
}

.btn{
width: 250px;
height: 40px;
margin: 5px 2px 2px 2px;
border-radius: 5px;
}

.primary{
background-color: skyblue;
color: white;
font-size: 15px;
font-weight: 1000;
}

.secondary{
background-color:#EC6A1F;
color: white;
font-size: 15px;
font-weight: 1000;
}

