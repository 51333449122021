.formappointment input{
    width: 500px;
    border-color: #EC6A1F;
    outline: none;
}
.contact,.formappointment{
    text-align: center;
}

.col h2{
    color: #EC6A1F;
    margin-left: 6px;
    text-align: center;
}

.mapimg{
    width: 500px;
    height: 320px;
    border-radius: 5px;
}

.form{
    align-items: right;
    text-align: right;
}

.title{
    background-color: aliceblue;
    width: 150px;
    text-align: center;
}
.contact{
    padding: 30px;
}
.contact-title{
    text-align: center;
    color: white;
}
.contact-top-div{
    padding: 100px;
    background-image: url('../../assets/img/sunrise.jpg');
}
@media only screen and (min-width: 320px) and (max-width: 830px){
    .row{
        display: flex;
        flex-direction: column;
    }
    .formappointment input{
        width: 250px;
        border-color: #EC6A1F;
        outline: none;
    }
    .mapimg{
        width: 250px;
        height: 250px;
        border-radius: 5px;
    }
    .contact-top-div{
        padding: 50px;
        background-image: url('../../assets/img/sunrise.jpg');
    }
}
