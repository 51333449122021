ul{
    padding: 20px 40px;
}

.box-template{
    border: solid 1px;
    border-color: bisque;
    border-radius: 15px;
    padding: 5px 5px;
    margin-bottom: 20px;
    background: #fff;
}
.box-template h3{
    padding: 20px 30px;
    color: #EC6A1F;
}
.box-template p{
    padding-left: 30px;
}
.box-template:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }