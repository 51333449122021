.cards{
    padding: 30px 100px;
    text-align: center;
}
.img-card {
    width: 40px;
    height: 40px;
}
.card-title{
    text-align: center;
    color: black;
    font-size: 14px;
    margin-top: 10px;
}
 figure{
    /* background-color: rgb(37, 54, 78); */
    border-radius: 10px;
    display: inline-block;
    margin-left: 20px;
    padding-left: 5px;
    margin-right: 20px;
} 

@media only screen and (min-width: 320px) and (max-width: 830px){
    figure{
        /* background-color: rgb(37, 54, 78); */
        border-radius: 10px;
        display: inline-block;
        margin-left: 0px;
        padding-left: 5px;
        margin-right: 0px;
        margin: 0;
    } 
    .cards{
        padding: 0px 0px;
        text-align: center;
    }
    .img-card {
        width: 95px;
        height: 95px;
        border-spacing: 0.1rem;
        padding: 30px;
        display: flex;
    }
    .card-title{
        font-size: 12px;
        margin-top: -20px;
        margin-bottom: 0rem;
    }
}