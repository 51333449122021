.main{
    width: 100%;
    display: flex;
    justify-content: right;
    position: fixed;
    background-color: #fff;
    z-index: 1000;
    padding: 1px 50px;
    height: 40px;
}

.icon{
    margin: 2px;
    text-align: center;
    font-size: 50px;
    line-height: 50px;
    overflow: hidden;
    /* box-shadow: 5px 10px 20px rgba(150, 150, 150, .3); */
    transition: all .3s ease-out;
    align-items: center;
    display: flex;
    cursor: pointer;
    height: 25px;
}

.btn-icon{
    background-color: #66bf53;
    color: #fff;
    font-weight: 600;
    border-color: #66bf53;
    padding-left: 10px;
    border: 1px solid;
    border-radius: 5px;
    margin-top: 5px;
}

.btn-icon1{
    background-color: #7e4396;
    color: #fff;
    font-weight: 600;
    border-color: #7e4396;
    padding-left: 10px;
    border: 1px solid;
    border-radius: 5px;
    margin-top: 5px;
}
.btn-icon2{
    background-color: #011E3A;
    color: #fff;
    font-weight: 600;
    border-color: #011E3A;
    padding-left: 1px;
    border: 1px solid;
    border-radius: 5px;
    margin-top: 5px;
}

@media only screen and (min-width: 320px) and (max-width: 991px){
    .main{
        flex-direction: column;
        display: none;
    }
}

.icon img{
    width: 18px;
    height: 18px;
    margin-top: 3px;
}

.icon span{
    font-size: 12px;
    margin-left: 5px;
}

.whatsapp{
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 2;
}