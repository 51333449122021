.info-top-div{
    padding: 100px;
    
}
.infra-title h1{
    color: white;
    text-align: center;
}

h3{
    text-align: left;
    color: #EC6A1F;
}